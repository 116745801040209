<template>
    <div class="row">
       <div class="col-md-3"> 
        <div class="box box-info contact-container-height">
            <div class="box-header with-border">
                <h3 class="box-title head-title-padding"><button class="btn btn-xs btn-link text-default"><i class="fa fa-pencil"></i></button> Contact Details</h3>
                <span class="pull-right">
                    <button class="btn btn-default"><span class="ai-icon">AI</span> Project Summary</button>
                </span>
            </div>
            <div class="box-body">
                <div class="location-img">
                     <img src="/img/estimate-location-image.png">
                </div>
                <div class="estimate-contact-details">
                   
                       <div class="estimate-contact-row"><span class="contact-icon"><i class="fa fa-user"></i></span>
                        <span class="contact-detail">John Hewitt</span>
                        </div>
                       <div class="estimate-contact-row"><span class="contact-icon"><i class="fa fa-map-marker"></i></span><span class="contact-detail">701 S Harrison Ave Kankakee, IL 60911</span></div>
                       <div class="estimate-contact-row"><span class="contact-icon"><i class="fa fa-phone"></i></span><span class="contact-detail">(501) 655-9429</span></div>
                       <div class="estimate-contact-row"><span class="contact-icon"><i class="fa fa-envelope"></i></span><span class="contact-detail">jhon.hewitt@example.com</span></div>
                   
                </div>
                <div class="contact-summary contact-actions">
                   <div class="row"> 
                    <div class="col-md-12">
                        <button class="btn btn-primary  mright-5 mbottom-5"><i class="fa fa-envelope "></i> Email Customer </button>
                        <button class="btn btn-success mright-5 mbottom-5"><i class="fa fa-edit"></i> Add Note</button>
                        <button class="btn bg-orange mright-5 mbottom-5"><i class="fa fa-clock-o "></i> History</button>
                    </div>
                </div>  
                    </div>
                
                    </div>
            
        </div> 
       </div>
       <div class="col-md-9"> 
            <div class="box box-info">
                <div class="box-header with-border">
                     <h3 class="box-title head-title-padding" v-if="estimatetab == true">Estimate Workflow</h3>
                     <h3 class="box-title head-title-padding mright-15" v-if="jobtab == true" v-on:click="">Job Workflow</h3>
                     <h3 class="box-title head-title-padding mright-15" v-if="jobtab == true" v-on:click="jobtab =false; estimatetab = true; step = 'schedule';">Convert to Estimate</h3>
                     <button class="btn btn-sm btn-primary pull-right" @click="assignestimate = true;">Assign to someone else</button>
                </div>

                <div class="box-body">
                    <div class="row" v-if="estimatetab==true">
                        <div class="col">
                            <div class="estimate-timeline timeline-steps aos-init aos-animate" data-aos="fade-up">
                                <div class="timeline-step">
                                    <p class="h6 text-muted mb-0 mb-lg-0 text-center"> Quote <br> Requested</p>
                                      <span class="estimate-activity-time">2024-05-05 <br>10:00 AM</span>
                                </div>
                                <div class="timeline-step" @click="showStep('schedule')">
                                    <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                                        <div class="inner-circle " v-bind:class = "step == 'schedule' ?'request-bg':' undone-steps'"><i class="fa fa-calendar"></i></div>
                                        <p class="h6 text-muted mb-0 mb-lg-0">Schedule Visit</p>
                                        <span class="estimate-activity-time">2024-05-05 <br>10:00 AM</span>
                                    </div>
                                </div>
                                <div class="timeline-step" @click="showStep('reminder')">
                                    <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2004">
                                        <div class="inner-circle" v-bind:class = "step == 'reminder' ?'visit-sche-bg':' undone-steps'"><i class="fa  fa-bell"></i></div>
                                        <p class="h6 text-muted mb-0 mb-lg-0">Send Reminder</p>
                                        <span class="estimate-activity-time">2024-05-05 <br>10:00 AM</span>
                                    </div>
                                </div>
                                <div class="timeline-step" @click="showStep('completeInspection')">
                                    <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2005">
                                        <div class="inner-circle" v-bind:class = "step == 'completeInspection' ?'visit-comp-bg':' undone-steps'"><i class="fa fa-check"></i></div>
                                        <p class="h6 text-muted mb-0 mb-lg-0">Inspect Property</p>
                                        <span class="estimate-activity-time">2024-05-05 <br>10:00 AM</span>
                                    </div>
                                </div>
                                <div class="timeline-step" @click="showStep('sendQuote')">
                                    <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2010">
                                        <div class="inner-circle" v-bind:class = "step == 'sendQuote' ?'quote-sent-bg':'undone-steps'"><i class="fa fa-file-text-o"></i></div>
                                        <p class="h6 text-muted mb-0 mb-lg-0">Prepare Quote </p>
                                        <span class="estimate-activity-time">2024-05-05 <br>10:00 AM</span>
                                    </div>
                                </div>
                                <div class="timeline-step mb-0" @click="showStep('approval')">
                                    <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2020">
                                        <div class="inner-circle" v-bind:class = "step == 'approval' ?'quote-appr-bg':'undone-steps'"><i class="fa fa-dollar"></i></div>
                                        <p class="h6 text-muted mb-0 mb-lg-0">Get Approval</p>
                                    </div>
                                </div>
                                <div class="timeline-step mb-0" @click="showStep('startJob')">
                                    <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2020">
                                        <div class="inner-circle" v-bind:class = "step == 'startJob' ?'install-sche-bg':'undone-steps'"><i class="fa  fa-gears "></i></div>
                                        <p class="h6 text-muted mb-0 mb-lg-0">Start Job</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="jobtab==true">
                        <div class="col">
                            <div class="estimate-timeline timeline-steps aos-init aos-animate" data-aos="fade-up">
                                <div class="timeline-step" @click="showStep('schedulejobinstall')">
                                    <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                                        <div class="inner-circle " v-bind:class = "step == 'schedulejobinstall' ?'request-bg':' undone-steps'"><i class="fa fa-calendar"></i></div>
                                        <p class="h6 text-muted mb-0 mb-lg-0">Schedule Install</p>
                                        <span class="estimate-activity-time">2024-05-05 <br>10:00 AM</span>
                                    </div>
                                </div>
                                <div class="timeline-step" @click="showStep('jobreminder')">
                                    <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2004">
                                        <div class="inner-circle" v-bind:class = "step == 'jobreminder' ?'visit-sche-bg':' undone-steps'"><i class="fa  fa-bell"></i></div>
                                        <p class="h6 text-muted mb-0 mb-lg-0">Send Reminder</p>
                                        <span class="estimate-activity-time">2024-05-05 <br>10:00 AM</span>
                                    </div>
                                </div>
                                <div class="timeline-step" @click="showStep('completejobinstall')">
                                    <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2005">
                                        <div class="inner-circle" v-bind:class = "step == 'completejobinstall' ?'visit-comp-bg':' undone-steps'"><i class="fa fa-check"></i></div>
                                        <p class="h6 text-muted mb-0 mb-lg-0">Complete Install</p>
                                        <span class="estimate-activity-time">2024-05-05 <br>10:00 AM</span>
                                    </div>
                                </div>
                                <div class="timeline-step" @click="showStep('jobfollowups')">
                                    <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2010">
                                        <div class="inner-circle" v-bind:class = "step == 'jobfollowups' ?'quote-sent-bg':'undone-steps'"><i class="fa fa-file-text-o"></i></div>
                                        <p class="h6 text-muted mb-0 mb-lg-0">Follow Ups</p>
                                        <span class="estimate-activity-time">2024-05-05 <br>10:00 AM</span>
                                    </div>
                                </div>
                                <div class="timeline-step mb-0" @click="showStep('startinvoices')">
                                    <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2020">
                                        <div class="inner-circle" v-bind:class = "step == 'startinvoices' ?'quote-appr-bg':'undone-steps'"><i class="fa fa-dollar"></i></div>
                                        <p class="h6 text-muted mb-0 mb-lg-0">Convert to Invoice</p>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-12">
                          <div class="nested-panel-heading skill-heading"><h3 class="nested-title">Project Description</h3></div>
                          My upstairs is always hot and downstairs is cold. The attic also seems to have lost a lot of the insulation over the years. I would like to improve the insulation go get a more consistent temprature in the house.
                        </div>
                    </div>
                </div>
            </div> 
            <div class="box box-info" v-if="assignestimate">
                <div class="box-header with-border">
                   <h3 class="box-title head-title-padding">Assign this estimate to someone else</h3>
                </div>
                <div class="box-body">
                    <div class="row form-horizontal">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="col-md-4 control-label text-left">Select New Agent</label>                                   
                                <div class="col-8">
                                    <Multiselect
                                    v-model="value"
                                    :closeOnSelect="false"
                                    placeholder="--Select Agent--"
                                    :options="{
                                        agent: 'Jacob Smith (smith@example.com)',
                                    
                                    }"
                                    />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button type="button" class="pull-left btn btn-primary" id="sendEmail">Assign </button>
                            <button type="button" class="pull-left btn btn-link" @click="assignestimate = false" >Cancel </button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="box box-info" v-if="estimatetab == true">
                <div class="box-header with-border">
                   <h3 class="box-title head-title-padding" v-if="step == 'schedule'">Scheduling Actions</h3>
                   <button class="btn btn-default ai-button pull-right" v-if="step == 'schedule'"><span class="ai-icon">AI</span> Ask for Dates</button>
                                
                   <h3 class="box-title head-title-padding" v-if="step == 'reminder'">Reminder Actions</h3>
                   <h3 class="box-title head-title-padding" v-if="step == 'completeInspection'">Inspection Actions</h3>
                   <h3 class="box-title head-title-padding" v-if="step == 'sendQuote'">Quote Actions</h3>
                   <h3 class="box-title head-title-padding" v-if="step == 'approval'">Approval Actions</h3>
                   <button class="btn btn-default ai-button pull-right" v-if="step == 'approval'"><span class="ai-icon">AI</span> Ask for Update</button>
                   <h3 class="box-title head-title-padding" v-if="step == 'startJob'">Convert to Job</h3>
                </div>
                <div class="box-body">
                    <div class="row" v-if="step == 'schedule'">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-left">Visit Date for Price Estimate</label>
                                        <div class="input-group date">
                                            <div class="input-group-addon" ng-click="isDatepickerEnd = !isDatepickerEnd"><i class="fa fa-calendar"></i></div>
                                            <input type="text" class="form-control small-input  pull-right" >
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-left">Arrival Window</label>
                                        <select class="form-control small-input">
                                             <option> 0 minute</option>
                                             <option> 15 minute</option>
                                             <option> 30 minute</option>
                                             <option> 45  minute</option>
                                             <option> 60 minute</option>
                                             <option> 90 minute</option>
                                             <option> 2 hours</option>
                                             <option> 3 hours</option>
                                             <option> 4 hours</option>
                                             <option> 5 hours</option>
                                             <option> 6 hours</option>
                                             <option> 7 hours</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-left">Visit Duration</label>
                                        <select class="form-control small-input">
                                             <option> 15 minute</option>
                                             <option> 30 minute</option>
                                             <option> 45  minute</option>
                                             <option> 60 minute</option>
                                             <option> 90 minute</option>
                                             <option> 2 hours</option>
                                             <option> 3 hours</option>
                                             <option> 4 hours</option>
                                             <option> 5 hours</option>
                                             <option> 6 hours</option>
                                             <option> 7 hours</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-left">Salesperson Assigned</label>
                                        <select class="form-control small-input">
                                             <option> --Select Salesperson--</option>
                                             <option> John Doe</option>
                                        </select>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="control-label text-left">Comment to Customer</label>                                   
                                        <textarea class="form-control"></textarea>
                                    </div>
                                </div>
                            </div>
                          
                            <div class="row">
                                <div class="col-md-12">
                                    <button class="btn btn-primary mright-5">Email Visit Notice</button>
                                    <button class="btn btn-primary mright-5">SMS Visit Notice</button>
                                   </div>
                            </div>
                        </div>
     
                    </div>
                    <div class="row form-horizontal" v-if="step == 'reminder'">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="form-group">
                                        <label class="col-md-4 control-label text-left">Salesperson Making Visit</label>                                   
                                        <div class="col-8">
                                            <Multiselect
                                            v-model="value"
                                            :closeOnSelect="false"
                                            placeholder="--Select the Salesperson--"
                                            :options="{
                                                salesperson: 'John Smith (johnsmith@example.com)',
                                            
                                            }"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="form-group">
                                        <label class="col-md-4 control-label text-left">Add Comment to SMS Notice (Optional)</label>                                   
                                        <div class="col-8">
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="form-group">
                                        <label class="col-md-4 control-label text-left">Add Comment to Email Notice (Optional)</label>                                   
                                        <div class="col-8">
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div class="col-md-5">
                                            <button type="button" class="pull-left btn btn-primary" id="sendEmail">Send Email Notice</button>
                                        </div>                                   
                                        <div class="col-7">
                                            <input type="text" class="form-control" value="john.hewitt@example.com">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div class="col-md-5">
                                            <button type="button" class="pull-left btn btn-primary" id="sendEmail">Send SMS Notice</button>
                                        </div>                                   
                                        <div class="col-7">
                                            <input type="text" class="form-control" value="+55656565656@e2s.messagemedia.com">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div>
                    <div class="row" v-if="step == 'completeInspection'">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="control-label text-left">Customer Notes</label>                                
                                        <textarea class="form-control"></textarea> 
                                      
                                    </div>     
                                    <button class="btn btn-primary">Save Customer Notes</button>                                         
                                </div>
                             
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="control-label text-left">Crew Notes</label>                                
                                        <textarea class="form-control"></textarea>                    
                                    </div> 
                                    <button class="btn btn-primary">Save Crew Notes</button>                           
                                </div>
                            
                            </div>
                            
                        </div>
                    </div>
                    <div class="row" v-if="step == 'sendQuote'">
                        <div class="col-md-12">
                           
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="control-label text-left">Customer Notes</label>                                   
                                        <textarea class="form-control"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button class="btn btn-primary">Email Quote</button>
                                </div>
                            </div>
                        </div>
                                              
                    </div>
                    <div class="row" v-if="step == 'approval'">
                        <div class="col-md-12">
                            <div class="mbottom-15">
                                <table class="table table-striped table-hover">
                                <thead>
                                <tr id="row1">
                                 
                                    <th style="width:120px;">
                                        Price Estimate Option
                                    </th>
                                    <th>
                                        Subtotal
                                    </th>
                                    <th>
                                        Adjustment
                                    </th>
                                    <th>
                                        Total Price
                                    </th>
                                    <th>
                                        &nbsp;
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                
                                    <td>
                                        Insulating
                                    </td>
                                    <td style="width:120px;">
                                       1150.00
                                    </td>
                                    <td>
                                     115.00
                                    </td>
                                    <td>
                                      1035.00
                                    </td>
                                    <td>
                                           <div class="d-flex">                         
                                            <button class="btn btn-sm btn-primary mright-5" v-if="!acceptMessage && !rejectMessage" @click="acceptMessage = true">Accept Quote</button>
                                            <button class="btn btn-sm btn-danger" v-if="!rejectMessage && !acceptMessage" @click="rejectMessage = true">Reject Quote</button>                                  
                                                <div v-if="rejectMessage">
                                                   <div class="d-inline-flex">          
                                                       <div>                    
                                                            <select class="form-control small-input mright-5">
                                                            <option>--Select Rejection Action--</option>
                                                            </select>
                                                        </div>
                                                        <button class="btn btn-sm btn-danger">Confirm Reject</button>  
                                                        <button class="btn btn-sm btn-link"  @click="rejectMessage = false; acceptMessage = false;">Cancel</button>  
                                                    </div>                
                                                </div> 

                                                <div v-if="acceptMessage">
                                                   <div class="d-inline-flex"> 
                                                        <div>                               
                                                            <select class="form-control small-input mright-5">
                                                                <option>--Select Thank you Template--</option>
                                                            </select>
                                                        </div>
                                                        <button class="btn btn-sm btn-primary">Confirm Accept</button>  
                                                        <button class="btn btn-sm btn-link"  @click="rejectMessage = false; acceptMessage = false;">Cancel</button> 
                                                    </div>                
                                                </div> 
                                            </div> 
                                           
                                    </td>
                                   
                                </tr>
                                <tr>
                              
                                <td>
                                    Base
                                </td>
                                <td style="width:120px;">
                                   1150.00
                                </td>
                                <td>
                                 115.00
                                </td>
                                <td>
                                  1035.00
                                </td>
                                <td>
                                       <div class="d-flex">                         
                                        <button class="btn btn-sm btn-primary mright-5" v-if="!acceptMessage1 && !rejectMessage1" @click="acceptMessage1 = true">Accept Quote</button>
                                        <button class="btn btn-sm btn-danger" v-if="!rejectMessage1 && !acceptMessage1" @click="rejectMessage1 = true">Reject Quote</button>                                  
                                            <div v-if="rejectMessage1">
                                               <div class="d-inline-flex"> 
                                                    <div>                             
                                                        <select class="form-control small-input mright-5">
                                                        <option>--Select Rejection Action--</option>
                                                        </select>
                                                    </div>
                                                    <button class="btn btn-sm btn-danger">Confirm Reject</button>  
                                                    <button class="btn btn-sm btn-link"  @click="rejectMessage1 = false; acceptMessage1 = false;">Cancel</button>  
                                                </div>                
                                            </div> 

                                            <div v-if="acceptMessage1">
                                               <div class="d-inline-flex">  
                                                    <div>                              
                                                        <select class="form-control small-input mright-5">
                                                        <option>--Select Thank you Template--</option>
                                                        </select>
                                                    </div>
                                                    <button class="btn btn-sm btn-primary">Confirm Accept</button>  
                                                    <button class="btn btn-sm btn-link"  @click="rejectMessage1 = false; acceptMessage1 = false;">Cancel</button>  
                                                </div>                
                                            </div> 
                                        </div> 
                                       
                                </td>
                               
                            </tr>
                           
                              </tbody>
                            </table>
                            </div>
                          
                        </div>
                    </div>
                    <div class="row" v-if="step == 'startJob'">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="control-label text-left">Crew Note</label>                                   
                                <textarea class="form-control"></textarea>
                            </div>
                               
                            <div class="form-horizontal">                              
                                <button class="btn btn-primary" v-on:click="estimatetab = false; jobtab = true; step = 'schedulejobinstall';">Start Job</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box box-info" v-if="jobtab == true">
                <div class="box-header with-border">
                   <h3 class="box-title head-title-padding" v-if="step == 'schedulejobinstall'">Scheduling Actions</h3>     
                   <h3 class="box-title head-title-padding" v-if="step == 'jobreminder'">Reminder Actions</h3>
                   <h3 class="box-title head-title-padding" v-if="step == 'completejobinstall'">Complete the Installation</h3>
                   <h3 class="box-title head-title-padding" v-if="step == 'jobfollowups'">Follow Ups</h3>
                   <h3 class="box-title head-title-padding" v-if="step == 'startinvoices'">Start Invoice</h3>
                </div>
                <div class="box-body">
                    <div class="row" v-if="step == 'schedulejobinstall'">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-left">Install Date</label>
                                        <div class="input-group date">
                                            <div class="input-group-addon" ng-click="isDatepickerEnd = !isDatepickerEnd"><i class="fa fa-calendar"></i></div>
                                            <input type="text" class="form-control small-input  pull-right" >
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-left">Arrival Window</label>
                                        <select class="form-control small-input">
                                             <option> 0 minute</option>
                                             <option> 15 minute</option>
                                             <option> 30 minute</option>
                                             <option> 45  minute</option>
                                             <option> 60 minute</option>
                                             <option> 90 minute</option>
                                             <option> 2 hours</option>
                                             <option> 3 hours</option>
                                             <option> 4 hours</option>
                                             <option> 5 hours</option>
                                             <option> 6 hours</option>
                                             <option> 7 hours</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-left">Visit Duration</label>
                                        <select class="form-control small-input">
                                             <option> 15 minute</option>
                                             <option> 30 minute</option>
                                             <option> 45  minute</option>
                                             <option> 60 minute</option>
                                             <option> 90 minute</option>
                                             <option> 2 hours</option>
                                             <option> 3 hours</option>
                                             <option> 4 hours</option>
                                             <option> 5 hours</option>
                                             <option> 6 hours</option>
                                             <option> 7 hours</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label text-left">Lead Technician</label>
                                        <select class="form-control small-input">
                                             <option> --Select Technician--</option>
                                             <option> John Doe</option>
                                        </select>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="control-label text-left">Comment to Customer</label>                                   
                                        <textarea class="form-control"></textarea>
                                    </div>
                                </div>
                            </div>
                          
                            <div class="row">
                                <div class="col-md-12">
                                    <button class="btn btn-primary mright-5">Email Install Notice</button>
                                    <button class="btn btn-primary mright-5">SMS Install Notice</button>
                                   </div>
                            </div>
                        </div>
     
                    </div>
                    <div class="row form-horizontal" v-if="step == 'jobreminder'">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="form-group">
                                        <label class="col-md-4 control-label text-left">Technician Making Visit</label>                                   
                                        <div class="col-8">
                                            <Multiselect
                                            v-model="value"
                                            :closeOnSelect="false"
                                            placeholder="--Select the Technician--"
                                            :options="{
                                                technician: 'John Smith (johnsmith@example.com)',
                                            }"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="form-group">
                                        <label class="col-md-4 control-label text-left">Add Comment to SMS Notice (Optional)</label>                                   
                                        <div class="col-12 d-flex">
                                            <div class="w-100">
                                               <input type="text" class="form-control">
                                            </div>
                                            <div>
                                                <button type="button" class="pull-left btn btn-primary small-input" id="sendEmail">Send SMS Notice</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="form-group">
                                        <label class="col-md-4 control-label text-left">Add Comment to Email Notice (Optional)</label>                                   
                                        <div class="col-12 d-flex">
                                            <div class="w-100">
                                               <input type="text" class="form-control">
                                            </div>
                                            <div>
                                                <button type="button" class="pull-left btn btn-primary small-input" id="sendEmail">Send Email Notice</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="form-group">                                 
                                        <div class="col-md-6">
                                            <input type="text" class="form-control" value="john.hewitt@example.com">
                                        </div>
                                        <div class="col-md-6">
                                            <input type="text" class="form-control" value="+55656565656@e2s.messagemedia.com">
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>                    
                    </div>
                    <div class="row" v-if="step == 'completejobinstall'">
                        <div class="col-md-10">
                            <table class="table">
                               <tr>
                                    <td style="border-color: rgb(255, 255, 255); width: 99.7059%;"><table style="font-size: 16px; width: 100%; border: 0px solid rgb(204, 204, 204); border-collapse: collapse;" width="100%" cellspacing="0" cellpadding="5" border="0"><tbody><tr style="background-color: rgb(200, 16, 46);"><td style="width: 150px; border-width: 1px; border-style: solid; border-color: rgb(200, 16, 46) rgb(255, 255, 255) rgb(200, 16, 46) rgb(200, 16, 46); border-image: none; color: rgb(255, 255, 255);"><strong>Workarea </strong></td><td style="width: 150px; border-width: 1px; border-style: solid; border-color: rgb(200, 16, 46) rgb(255, 255, 255) rgb(200, 16, 46) rgb(200, 16, 46); border-image: none; color: rgb(255, 255, 255);"><strong>Material</strong></td><td style="border-width: 1px; border-style: solid; border-color: rgb(200, 16, 46) rgb(255, 255, 255) rgb(200, 16, 46) rgb(200, 16, 46); border-image: none; color: rgb(255, 255, 255);"><strong>Notes</strong></td><td style="width: 120px; border: 1px solid rgb(200, 16, 46); color: rgb(255, 255, 255);"><stong> Price</stong></td></tr><tr style="background-color: rgb(255, 255, 255);"><td style="width: 150px; border: 1px solid rgb(204, 204, 204);"> Base </td><td style="width: 150px; border: 1px solid rgb(204, 204, 204);"> Insulation </td><td style="border: 1px solid rgb(204, 204, 204);"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </td><td style="width: 120px; border: 1px solid rgb(204, 204, 204);"> $1400.00 </td></tr></tbody></table></td>
                               </tr>
                            </table>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-primary">Accept Installation</button>
                        </div>
                    </div>
                    <div class="row" v-if="step == 'jobfollowups'">
                        <div class="col-md-12">
                            
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Send thank you email message  
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Add to nurture marketing campaign
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Add to Mailing List
                                    </label>
                                </div>
                                <div class="mtop-15">
                                    <button class="btn btn-primary">Perform Follow Up Actions</button>
                                </div>
                        </div>
                                              
                    </div>
                    <div class="row" v-if="step == 'startinvoices'">
                        <div class="col-md-12">
                            <div class="form-group"><label class="control-label text-left">Comments for Accounts Receivable (Optional)</label><textarea class="form-control"></textarea></div>
                            <div class="form-horizontal"><button class="btn btn-primary">Create Invoice</button></div>
                        </div>
                    </div>
                 
                </div>
            </div>
           
       </div>
       <div class="col-md-12" v-if="estimatetab == true"> 
        <div class="nav-tabs-custom">
         <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation" v-if="step == 'schedule' || step == 'reminder' || step == 'completeInspection'  || step == 'startJob'">
               <a class="nav-link active" v-bind:id="'tab11'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_11_'+tabOption" type="button" role="tab" aria-controls="profile" aria-selected="false"><i class="fa fa-calendar"></i> <span>My Schedule</span></a>
            </li>
            <li class="nav-item" role="presentation" v-if="step == 'schedule'">
               <a class="nav-link" v-bind:id="'tab61'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_61_'+tabOption" type="button" role="tab" aria-controls="profile" aria-selected="false"><i class="fa fa-eye"></i> <span>Preview Visit Notice (Email)</span></a>
            </li>
            <li class="nav-item" role="presentation" v-if="step == 'schedule'">
               <a class="nav-link" v-bind:id="'tab62'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_62_'+tabOption" type="button" role="tab" aria-controls="profile" aria-selected="false"><i class="fa fa-eye"></i> <span>Preview Visit Notice (SMS)</span></a>
            </li>
            <li class="nav-item" role="presentation" v-if="step == 'reminder'">
               <a class="nav-link" v-bind:id="'tab21'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_21_'+tabOption" type="button" role="tab"><i class="fa fa-eye"></i> <span>Preview Email Reminder</span></a>
            </li>
            <li class="nav-item" role="presentation" v-if="step == 'reminder'">
               <a class="nav-link" v-bind:id="'tab31'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_31_'+tabOption" type="button" role="tab"><i class="fa fa-eye"></i> <span>Preview SMS Reminder</span></a>
            </li>
            <li class="nav-item" role="presentation" v-if="step == 'sendQuote'">
               <a class="nav-link active" v-bind:id="'tab41'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_41_'+tabOption" type="button" role="tab"><i class="fa fa-dollar"></i> <span>Estimator</span></a>
            </li>
            <li class="nav-item" role="presentation" v-if="step == 'sendQuote' || step == 'approval'">
               <a class="nav-link" v-bind:class="approval ? 'active':'tab51'" v-bind:id="'tab51'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_51_'+tabOption" type="button" role="tab"><i class="fa fa-eye"></i> <span>Preview Quote</span></a>
            </li>

         </ul>
         <div class="tab-content" id="myTabContent">
            <div v-if="step == 'schedule' || step == 'reminder' || step == 'completeInspection' || step == 'startJob'  " class="tab-pane show active" v-bind:id="'tab_11_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">  
                <div class="row">
                    <div class="col-md-12">
                        <ScheduleXCalendar
                            :calendar-app="calendarApp"
                            :custom-components="customComponents"
                        >
                        </ScheduleXCalendar>
                    </div>
                </div>
            </div>

            <div v-if="step == 'reminder'" class="tab-pane show active" v-bind:id="'tab_21_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
               <MyEstimateEmailReminderPreview></MyEstimateEmailReminderPreview>
            </div>
            <div v-if="step == 'reminder'"  class="tab-pane show active" v-bind:id="'tab_31_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                <div class="form-group text-editor-container mtop-15"><textarea class="form-control" rows="10" placeholder="" id="editorgetthis" ckeditor="">&lt;p&gt;Hello,&lt;/p&gt;
                        &lt;p&gt;Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,

                        &lt;p&gt;Regards,&lt;/p&gt;

                        &lt;p&gt;John Doe&lt;/p&gt;
                    </textarea>
                </div>
            </div>

            <div v-if="step == 'sendQuote' " class="tab-pane show active" v-bind:id="'tab_41_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                <div class="row">
                   <div class="col-md-12">
                      <label class="control-label text-left col-12">Work Order Details</label>
                      <div class="table-responsive pb-3">
                        <table style="width: 100%;">
                            <tr>
                                <th></th>
                                <th>Option</th>
                                <th>Phase</th>
                                <th>Work Area</th>
                                <th>QTY</th>
                                <th>Material</th>
                                <th>SKU</th>
                                <th>Customer Material</th>
                                <th>Unit</th>
                                <th>Price</th>
                                <th>Crew Notes</th>
                                <th>Discount</th>
                                <th>Ext Price</th>
                                <th>Customer Notes</th>
                            </tr>
                            <tr id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroWorkOrderDetails_0">
                                <td style="border-top: medium !important;"><button class="icon-button button-delete" type="button" id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroWorkOrderDetails_0_RemoveRow"><i class="fa fa-times-circle"></i></button></td>
                                <td style="min-width: 135px;">
                                    <Multiselect
                                            v-model="value"
                                            :closeOnSelect="false"
                                            placeholder="--Select option--"
                                            :options="{
                                                option1: 'option1',
                                            
                                            }"
                                        />
                                </td>
                                <td style="min-width: 135px;">
                                    <Multiselect
                                            v-model="value"
                                            :closeOnSelect="false"
                                            placeholder="--Pick option--"
                                            :options="{
                                                option1: 'Retrofit Attick Insulation',
                                            
                                            }"
                                        />
                                </td>
                                <td style="min-width: 135px;">
                                    <Multiselect
                                            v-model="value"
                                            :closeOnSelect="false"
                                            placeholder="--Pick option--"
                                            :options="{
                                                option1: 'Attick',
                                            
                                            }"
                                        />
                                </td>
                                <td style="min-width: 60px;"><input class="form-control" type="text" inputmode="numeric" id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroWorkOrderDetails_0_QTY"></td>
                                <td style="min-width: 150px;">
                                    <Multiselect
                                            v-model="value"
                                            :closeOnSelect="false"
                                            placeholder="--Pick option--"
                                            :options="{
                                                option1: 'Attick Access Cover',
                                            
                                            }"
                                        />
                                </td>
                                <td style="min-width: 100px;"><input class="form-control" disabled="" maxlength="450" id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroWorkOrderDetails_0_SKU"></td>
                                <td style="min-width: 150px;"><input class="form-control" disabled="" maxlength="450" id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroWorkOrderDetails_0_CustomerMaterial"></td>
                                <td style="min-width: 60px;"><input class="form-control" disabled="" maxlength="450" id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroWorkOrderDetails_0_Unit"></td>
                                <td style="min-width: 60px;"><input class="form-control" disabled="" maxlength="450" id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroWorkOrderDetails_0_Price"></td>
                                <td style="min-width: 150px;"><input class="form-control" maxlength="450" id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroWorkOrderDetails_0_CrewNotes"></td>
                                <td style="min-width: 60px;"><input class="form-control" type="text" inputmode="numeric" id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroWorkOrderDetails_0_Discount"></td>
                                <td style="min-width: 60px;"><input class="form-control" disabled="" maxlength="450" id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroWorkOrderDetails_0_ExtPrice"></td>
                                <td style="min-width: 110px;"><input class="form-control" maxlength="450" id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroWorkOrderDetails_0_CustomerNotes"></td>
                            </tr>
                            </table>

                      </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row mbottom-15" id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroPriceEstimate">
                            <label class="control-label text-left col-12">Price Estimate</label>
                            <div class="overflow-box-x col-12" style="padding-right: 25px;">
                                <table style="width: 100%;">
                                    <tr>
                                    <!--v-if-->
                                    <th>Option</th>
                                    <th>Subtotal</th>
                                    <th>Adjustment</th>
                                    <th>Total Price</th>
                                    </tr>
                                    <tr id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroPriceEstimate_0">
                                    <!--v-if-->
                                    <td style="min-width: 110px;"><input class="form-control" value="Base" maxlength="450" id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroPriceEstimate_0_Option"></td>
                                    <td style="min-width: 110px;"><input class="form-control" value="7500.00" disabled="" maxlength="450" id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroPriceEstimate_0_Subtotal"></td>
                                    <td style="min-width: 110px;"><input class="form-control" value="9.111" type="text" inputmode="numeric" id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroPriceEstimate_0_Adjustment"></td>
                                    <td style="min-width: 110px;"><input class="form-control" value="7499.00" disabled="" maxlength="450" id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroPriceEstimate_0_TotalPrice"></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-8">
                        <div class="row" id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroTotalEstimateForAllOptions">
                            <label class="control-label text-left col-4">Total Estimate for All Options</label>
                            <div class="col-8">
                                <div class="simple-label pos-rel single">
                                    <div class="float-btn-group" id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroTotalEstimateForAllOptions_0">
                                    <!--v-if-->
                                    <div class="property-field">
                                        <!--v-if--><input class="form-control" value="7499.00" disabled="" maxlength="450" id="MyInbox_Message57088_WorkflowRetro_Tab0_PropRetroTotalEstimateForAllOptions_0_Value">
                                    </div>
                                    </div>
                                    <!--v-if-->
                                </div>
                            </div>
                        </div>
                       
                    </div>

                </div>
            </div>
            <div v-if="step == 'sendQuote' || step == 'approval'"  class="tab-pane show active" v-bind:id="'tab_51_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                <EstimateQuotePreview> </EstimateQuotePreview>
            </div>
            <div v-if="step == 'schedule'"  class="tab-pane" v-bind:id="'tab_61_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                <EmailVisitNotice> </EmailVisitNotice>
            </div>
            <div v-if="step == 'schedule'"  class="tab-pane" v-bind:id="'tab_62_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                <textarea class="form-control">Hello, <br>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                </textarea>
            </div>

         </div>
        </div>
       </div>
    </div>
    <button style="visibility:hidden" type="button" id="event-details-popup" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
      event detail
    </button>
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"></h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body text-left">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="box" id="Calendar_Goley_Goley">
                                <div class="box-body">
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <label>First Name</label><input type="text" class="form-control" name="firstName">
                                            
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>Last Name</label><input type="text" class="form-control" name="lastName">
                                            
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>Email</label><input type="text" class="form-control" name="email">
                                            
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>Mobile Number</label><input type="text" class="form-control" name="mobile number">
                                            
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label>Property Address</label><input type="text" class="form-control" name="property address">
                                            
                                        </div>

                                        <div class="form-group col-md-4">
                                            <label>City</label><input type="text" class="form-control" name="city">
                                            
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label>State</label><input type="text" class="form-control" name="state">
                                            
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label>Zip Code</label><input type="text" class="form-control" name="zip code">
                                            
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label>Project Description</label><input type="text" class="form-control" name="description">
                                            
                                        </div>

                                        <div class="form-group col-md-8">
                                            <label>Sales Rep.</label>
                                            <select class="form-control"><option>--Pick A Value---</option></select>
                                            
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <div class="btn-group margin-r-5">
                                                    <button class="btn btn-primary">
                                                        <!--v-if--> Submit Request
                                                    </button>
                                                    <!--v-if-->
                                                </div>
                                                <!--v-if-->
                                            </div>
                                        </div>
                                        <div class="col-12"><div class="iservice-switch"><label class="switch"><input type="checkbox" iservice-switch=""><i></i> Please notify me via SMS for important updates</label></div></div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="modal-body text-right">
                    <a class="btn btn-primary" href @click.prevent="feature.saveEvent()">Save</a>
                    <a class="btn btn-primary ms-3" @click.prevent="feature.cancelSaveEvent()">Cancel</a>
                    </div>-->
                </div>
        </div>
        </div>
 </template>
 <style src="@vueform/toggle/themes/default.css"></style>
 <script>
     

     import * as Vue from 'vue'
     import Toggle from '@vueform/toggle'
     import Multiselect from '@vueform/multiselect'
     import iServiceSwitch from '../components/iServiceSwitch.vue';
     import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';
     import MyEstimateEmailReminderPreview from '../components/MyEstimateEmailReminderPreview.vue';
     import EstimateQuotePreview from '../components/EstimateQuotePreview.vue';
     import MyEstimateEstimater from '../components/MyEstimateEstimater.vue';
     import EmailVisitNotice from '../components/EmailVisitNotice.vue';
     import { ScheduleXCalendar } from '@schedule-x/vue'
     var iservice = window.iservice || {};

     iservice.date = {};
  ((date) => {
    function isValid(date) {
      if (!date) return false;
      if (!(date instanceof Date)) return false;
      return date.toString() != 'Invalid Date';
    }
    function makeDate(obj) {
      if (obj instanceof Date) return obj;
      if (typeof obj == 'string' || obj instanceof String) {
        return new Date(obj);
      }
      return null;
    }
    function utcYYYY(date) { return `${date.getUTCFullYear()}`; }
    function utcMM(date) { var month = `${date.getUTCMonth() + 1}`; return month.length < 2 ? `0${month}` : month; }
    function utcDD(date) { var day = `${date.getUTCDate()}`; return day.length < 2 ? `0${day}` : day; }
    function localYYYY(date) { return `${date.getFullYear()}`; }
    function localMM(date) { var month = `${date.getMonth() + 1}`; return month.length < 2 ? `0${month}` : month; }
    function localM(date) { return `${date.getMonth() + 1}`; }
    function localDD(date) { var day = `${date.getDate()}`; return day.length < 2 ? `0${day}` : day; }
    function localHH(date) { var hours = `${date.getHours()}`; return hours.length < 2 ? `0${hours}` : hours; }
    function localmm(date) { var minute = `${date.getMinutes()}`; return minute.length < 2 ? `0${minute}` : minute; }
    function localss(date) { var seconds = `${date.getSeconds()}`; return seconds.length < 2 ? `0${seconds}` : seconds; }
    function localms(date) { var ms = `${dateObj.getMilliseconds()}`; return ms.length < 2 ? `00${ms}` : (ms.length < 3 ? `0${ms}` : ms); }

    date.apiDateIn = function (utcStr) {
      var utc = new Date(utcStr);
      if (!isValid(utc)) return null;
      var local = new Date();
      local.setFullYear(utc.getUTCFullYear(), utc.getUTCMonth(), utc.getUTCDate());
      return local;
    }
    date.apiDateOut = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      return `${iservice.date.localYYYYMMDD(date)}T00:00:00.000Z`
    }
    date.apiDateExport = function (utcStr) { //export to excel. directly output the utc date sent from api.
      var utc = new Date(utcStr);
      if (!isValid(utc)) return null;
      return utc;
    }
    date.apiDatetimeIn = function (utcStr) {
      var utc = new Date(utcStr);
      if (!isValid(utc)) return null;
      return utc;
    }
    date.apiDatetimeOut = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      return date.toISOString();
    }
    date.apiDatetimeExport = function (utcStr) { //export to excel. take utc from api, get local values and store in utc time zone.
      var local = new Date(utcStr);
      if (!isValid(local)) return null;
      var utc = new Date();
      utc.setUTCFullYear(local.getFullYear(), local.getMonth(), local.getDate());
      utc.setUTCHours(local.getHours(), local.getMinutes(), local.getSeconds(), local.getMilliseconds());
      return utc;
    }
    date.utcYYYYMMDD = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      return `${utcYYYY(date)}-${utcMM(date)}-${utcDD(date)}`;
    }
    date.localMDD = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      return `${localM(date)}/${localDD(date)}`;
    }
    date.localYYYYMMDD = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      return `${localYYYY(date)}-${localMM(date)}-${localDD(date)}`;
    }
    date.localYYYYMMDDHmm = function (date, minute) {
      if (!isValid(date = makeDate(date))) return '';
      if (minute) date.setMinutes(date.getMinutes() + minute);
      var hours = date.getHours();
      hours = hours < 10 ? `0${hours}` : `${hours}`;
      var minutes = date.getMinutes();
      minutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      return `${localYYYY(date)}-${localMM(date)}-${localDD(date)} ${hours}:${minutes}`;
    }
    date.localYYYYMMDDHmmA = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      var hours = date.getHours();
      var hour = hours == 0 ? '12' : (hours < 13 ? `${hours}` : `${hours - 12}`);
      var ampm = hours < 12 ? 'AM' : 'PM';
      return `${localYYYY(date)}-${localMM(date)}-${localDD(date)} ${hour}:${localmm(date)} ${ampm}`;
    }
    date.localYYYYMMDDHmmssA = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      var hours = date.getHours();
      var hour = hours == 0 ? '12' : (hours < 13 ? `${hours}` : `${hours - 12}`);
      var ampm = hours < 12 ? 'AM' : 'PM';
      return `${localYYYY(date)}-${localMM(date)}-${localDD(date)} ${hour}:${localmm(date)}:${localss(date)} ${ampm}`;
    }
    date.localYYYY = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      return `${localYYYY(date)}`;
    }
    date.localHmmA = function (date) {
      if (!isValid(date = makeDate(date))) return '';
      var hours = date.getHours();
      var hour = hours == 0 ? '12' : (hours < 13 ? `${hours}` : `${hours - 12}`);
      var ampm = hours < 12 ? 'AM' : 'PM';
      return `${hour}:${localmm(date)} ${ampm}`;
    }
  })(iservice.date);
  var feature = Vue.reactive({ isCalendar: true, preFixId: "", postFixId: "", selectedDate: "", events: [], errors: [], sucess: null, calendars: {}, event: {} });
    import {
    createCalendar,
    viewMonthAgenda,
    viewMonthGrid,
    viewWeek
    } from '@schedule-x/calendar'
    import '@schedule-x/theme-default/dist/index.css'
    import {createEventRecurrencePlugin, createEventsServicePlugin} from "@schedule-x/event-recurrence";
    import { CalendarEvent } from '@schedule-x/calendar'
    import { createResizePlugin } from '@schedule-x/resize'
    import {createEventModalPlugin} from "@schedule-x/event-modal";
    import {createDragAndDropPlugin} from "@schedule-x/drag-and-drop";
    import {createScrollControllerPlugin} from "@schedule-x/scroll-controller";
    import { createSidebarPlugin } from "@sx-premium/sidebar";
    import { createInteractiveEventModal } from "@sx-premium/interactive-event-modal";
   
    import '@sx-premium/sidebar/dist/index.css'
    import '@sx-premium/interactive-event-modal/dist/index.css'

     import { ref, watchEffect } from "vue"
     export default {
       name: 'My Estimate Details',
       props: ['prop'],
       emits: ['onAction'],
        components: {
          Multiselect,
          iServiceSwitch,
          Toggle,
          VariableAndStockResponses,
          MyEstimateEmailReminderPreview,
          EstimateQuotePreview,
          EmailVisitNotice,
          ScheduleXCalendar,
            createCalendar,
            viewMonthAgenda,
            viewMonthGrid,
            viewWeek,
            createEventRecurrencePlugin, createEventsServicePlugin,
            //CalendarEvent,
            createResizePlugin,
            createEventModalPlugin,
            createDragAndDropPlugin,
            createScrollControllerPlugin,
            createSidebarPlugin,
            createInteractiveEventModal,
            
       },
       data: function () {
         return {
            allowmultiple : false,
            customerviewable : false,
            schedule : true,
            reminder: false,
            completeInspection: false,
            sendQuote  : false,
            approval : false,
            startJob:false,
            step : 'schedule',
            assignestimate : false,
            rejectMessage : false,
            acceptMessage : false,
            estimatetab : true,
            jobtab : false,
            rejectMessage1 : false,
            acceptMessage1 : false,

         }
       },
       events: {
        
       },
       mounted() { 
            
        },
       methods: {
         showStep(stepdata){
            this.step = stepdata;
         },
         showModal() {
            document.getElementById("event-details-popup").click(); 
        }
       },
       setup(props,context){
        
        const eventsService = createEventsServicePlugin();
        const interactiveEventModal = createInteractiveEventModal({
        eventsService,
        availablePeople: ['John Doe', 'Jane Doe'],
        onAddEvent: (event) => {
            console.log('Event added', event)
        }
        });
        const sidebar = createSidebarPlugin({
            eventsService,
            
            // Optional: Should the sidebar be open on render
            openOnRender: false,
            
            // Optional: Which calendars should be active on render
            activeCalendarIds: ['personal', 'work', 'leisure', 'school'],
            
            // Optional: Should there be calendar toggles
            hasCalendarToggles: true,
            
            // Optional: placeholder events for drag-to-create
            placeholderEvents: [
                {
                title: 'Morning brief',
                calendarId: 'internal',
                people: ['John Doe', 'Jane Doe', 'Steve Smith'],
                },
                {
                title: 'Client demo',
                calendarId: 'internal',
                people: ['John Doe', 'Jane Doe'],
                },
                {
                title: 'Team meeting',
                calendarId: 'clients',
                people: ['John Doe', 'Jane Doe', 'Steve Smith'],
                }
            ],
            
            // Optional: factory function for generating event ids when events are created
            idFactory: () => uuidv4() // or any other id generator
            })
        
        let isSelecting = false;
        let selectionStart = null;
        let selectionEnd = null;
        let selectionOverlay = null;
        Vue.watchEffect(() => {
            document.addEventListener('mousedown', function (event) {
            if (event.target && event.target.classList && event.target.classList.length && event.target.classList[0] == 'sx__time-grid-day') {
                selectionStart = null;
                selectionEnd = null;
                isSelecting = true;
                selectionStart = getDateAtEvent(event);
                // Create and style the selection overlay
                selectionOverlay = document.createElement('div');
                selectionOverlay.classList.add('selection-overlay');
                
                // Initialize the overlay position and size
                updateOverlay(event);
            }
            });
            document.addEventListener('mousemove', function (event) {
            if (isSelecting) {
                if ((event.offsetY % 15) > 0) return;
                let selectElement = event.target?.getElementsByClassName('overlay-start-time')
                if (selectElement.length == 0) event.target.appendChild(selectionOverlay);

                let startTime = event.target?.getElementsByClassName('overlay-start-time');
                if (startTime.length > 0) {
                startTime[0].innerHTML = selectionStart.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                } else {
                startTime = document.createElement('span');
                startTime.classList.add('overlay-start-time');
                startTime.innerHTML = selectionStart.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                selectionOverlay.appendChild(startTime);
                }

                selectionEnd = getDateAtEvent(event);
                let start = null;
                let end = null;
                startTime = event.target?.getElementsByClassName('overlay-start-time');
                start = new Date(selectionStart.date);
                start.setHours(0, 0, 0, 0);
                end = new Date(selectionEnd.date);
                end.setHours(0, 0, 0, 0);
                if (selectionStart.clientY < event.offsetY) {
                start.setMinutes(selectionStart.clientY);
                end.setMinutes(event.offsetY);
                } else {
                start.setMinutes(event.offsetY);
                end.setMinutes(selectionStart.clientY);
                }
                selectionStart.date = start;
                selectionEnd.date = end;
                startTime[0].innerHTML = start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                let endTime = document.getElementsByClassName('overlay-end-time');
                if (endTime.length > 0) {
                endTime[0].innerHTML = end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                } else {
                endTime = document.createElement('span');
                endTime.classList.add('overlay-end-time');
                endTime.innerHTML = selectionEnd.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                selectionOverlay.appendChild(endTime);
                }
                updateOverlay(event); // Update overlay position and size
            }
            });
            document.addEventListener('mouseup', function (event) {
            if (isSelecting) {
                isSelecting = false;
                if (!selectionEnd) return;
                let start = null;
                let end = null;
                start = new Date(selectionStart.date);
                start.setHours(0, 0, 0, 0);
                end = new Date(selectionEnd.date);
                end.setHours(0, 0, 0, 0);
                if (selectionStart.clientY < selectionEnd.clientY) {
                start.setMinutes(selectionStart.clientY);
                end.setMinutes(selectionEnd.clientY);
                } else {
                start.setMinutes(selectionEnd.clientY);
                end.setMinutes(selectionStart.clientY);
                }
                selectionStart.date = start;
                selectionEnd.date = end;
                if (selectionStart && selectionEnd) {
                feature.title = "";
                if (props.config?.startProperty && allValues[props.config?.startProperty]) {
                    allValues[props.config?.startProperty].Details([{ valueDatetimeUtc: iservice.date.apiDatetimeOut(selectionStart.date) }]);
                }
                if (props.config?.endProperty && allValues[props.config?.endProperty]) {
                    allValues[props.config?.endProperty].Details([{ valueDatetimeUtc: iservice.date.apiDatetimeOut(selectionEnd.date) }]);
                }
                feature.isCreateEventForm = true;
                }
                document.getElementById("event-details-popup").click(); 
            }
            });
            function updateOverlay(event) {
             
            const startY = Math.min(event.offsetY, selectionStart.clientY);
            const endY = Math.max(event.offsetY, selectionStart.clientY);

            //selectionOverlay.style.left = `${startX}px`;
            selectionOverlay.style.top = `${startY}px`;
            selectionOverlay.style.width = `100%`;
            selectionOverlay.style.height = `${endY - startY}px`;
            }
            function getDateAtEvent(event) {
            let eventY = (parseInt(JSON.parse(JSON.stringify(event.offsetY)) / 15)) * 15;
            
            const date = new Date(iservice.date.apiDateIn(event.target.dataset.timeGridDate).setHours(0, 0, 0, 0));
          
            //date.setMinutes(eventY);
            // Include client coordinates in the returned object for overlay positioning
            return {
                date: date,
                clientX: event.offsetX,
                clientY: eventY
            };
            }
        }); 
        const calendarApp = createCalendar({
        selectedDate: '2024-08-22',
        locale: 'en-UK',
        views: [viewMonthAgenda, viewMonthGrid, viewWeek],
        defaultView: viewWeek.name,
        plugins: [
            createEventModalPlugin(),
            createDragAndDropPlugin(30),
            createScrollControllerPlugin(),
            createEventRecurrencePlugin(),
            createResizePlugin(),
            eventsService,
            sidebar,
            interactiveEventModal,
            
        ],
        callbacks: {
            onRangeUpdate(range) {
        console.log('new calendar range start date', range.start)
        console.log('new calendar range end date', range.end)
        },
    
        /**
         * Is called when an event is updated through drag and drop
         * */
        onEventUpdate(updatedEvent) {
        console.log('onEventUpdate', updatedEvent)
        },
    
        /**
        * Is called when an event is clicked
        * */
        onEventClick(calendarEvent) {
        console.log('onEventClick', calendarEvent)
        },
    
        /**
        * Is called when clicking a date in the month grid
        * */
        onClickDate(date) {
        console.log('onClickDate', date) // e.g. 2024-01-01
        },
    
        /**
        * Is called when clicking somewhere in the time grid of a week or day view
        * */
        onClickDateTime(dateTime) {
        console.log('onClickDateTime', dateTime) // e.g. 2024-01-01 12:37
        },
    
        /**
        * Is called when selecting a day in the month agenda
        * */
        onClickAgendaDate(date) {
        console.log('onClickAgendaDate', date) // e.g. 2024-01-01
        },
    
        /**
        * Is called when double clicking a date in the month grid
        * */
        onDoubleClickDate(date) {
        console.log('onClickDate', date) // e.g. 2024-01-01
        },
    
        /**
        * Is called when double clicking somewhere in the time grid of a week or day view
        * */
        onDoubleClickDateTime(dateTime) {
        console.log('onDoubleClickDateTime', dateTime) // e.g. 2024-01-01 12:37
        },
    
        /**
        * Is called when clicking the "+ N events" button of a month grid-day
        * */
        onClickPlusEvents(date) {
        console.log('onClickPlusEvents', date) // e.g. 2024-01-01
        },
    
        /**
        * Is called when the selected date is updated
        * */
        onSelectedDateUpdate(date) {
        console.log('onSelectedDateUpdate', date)
        },
    
            },
        events: [
            {
            id: 1,
            title: 'Estimate request',
            start: '2024-08-22',
            end: '2024-08-22',
            }
        
        ],
        calendars: {
            leisure: {
            colorName: 'leisure',
            lightColors: {
                main: '#1c7df9',
                container: '#d2e7ff',
                onContainer: '#002859',
            },
            darkColors: {
                main: '#c0dfff',
                onContainer: '#dee6ff',
                container: '#426aa2',
            },
            },
            },
                monthGridOptions: {
                    nEventsPerDay: 3,
                }
                })
                return{calendarApp};
        
        },  

       
        
     }
        
 </script>